import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Carousel, Button } from 'antd';
import { ModalWindow } from '../ModalWindow';
import { postUserShowOnboard } from '../../store/user/actions';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import customStyle from '../../shared/styles/custom.module.scss';
import onboarding1 from '../../shared/images/onboarding/onboarding1.jpg';
import onboarding2 from '../../shared/images/onboarding/onboarding2.jpg';
import onboarding3 from '../../shared/images/onboarding/onboarding3.jpg';
import onboarding4 from '../../shared/images/onboarding/onboarding4.jpg';
import onboarding5 from '../../shared/images/onboarding/onboarding5.jpg';
import onboarding6 from '../../shared/images/onboarding/onboarding6.jpg';
import onboarding7 from '../../shared/images/onboarding/onboarding7.jpg';
import onboardingMob1 from '../../shared/images/onboarding/mob1.jpg';
import onboardingMob2 from '../../shared/images/onboarding/mob2.jpg';
import onboardingMob3 from '../../shared/images/onboarding/mob3.jpg';
import onboardingMob4 from '../../shared/images/onboarding/mob4.jpg';
import onboardingMob5 from '../../shared/images/onboarding/mob5.jpg';
import onboardingMob6 from '../../shared/images/onboarding/mob6.jpg';
import onboardingMob7 from '../../shared/images/onboarding/mob7.jpg';

export const Onboarding = ({ show, close, openedByUser, onboardId }) => {
  const dispatch = useDispatch();
  const [desktopView, setDesktopView] = useState(false);

  const getWindowDimensions = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }, []);

  const handleResize = useCallback(() => {
    const currentView = getWindowDimensions();
    if (currentView.width >= 1000) {
      setDesktopView(true);
    } else if (currentView.width <= 768) {
      setDesktopView(false);
    }
  }, [setDesktopView, getWindowDimensions]);

  useEffect(() => {
    let isHandle = true;
    if (isHandle) {
      handleResize();
    }

    return () => {
      isHandle = false;
    };
  }, [handleResize]);

  const arrayOfOnboardingImagesInDesktop = [
    onboarding1,
    onboarding2,
    onboarding3,
    onboarding4,
    onboarding5,
    onboarding6,
    onboarding7,
  ];

  const arrayOfOnboardingImagesInMobile = [
    onboardingMob1,
    onboardingMob2,
    onboardingMob3,
    onboardingMob4,
    onboardingMob5,
    onboardingMob6,
    onboardingMob7,
  ];

  const carouselRef = useRef(null);

  const handleNext = () => carouselRef.current.next();

  const handlePrev = () => carouselRef.current.prev();

  const userShowOnboard = () => {
    if (!openedByUser) {
      dispatch(postUserShowOnboard(onboardId));
    }
  };

  const handleOnboardingScrolled = (current, next) => {
    const imagesLength = desktopView
      ? arrayOfOnboardingImagesInDesktop.length
      : arrayOfOnboardingImagesInMobile.length;
    const dots = imagesLength - 1;
    if (current === dots && next === 0) {
      close(false);
      userShowOnboard();
    }
  };

  return (
    <ModalWindow
      onCancel={userShowOnboard}
      modalVisible={show}
      modalHidden={close}
      closable={true}
      carousel={true}
      content={[
        <Carousel
          key="carousel"
          className={styles.carousel}
          dots={{ className: styles.dotsStyle }}
          beforeChange={(c, n) => handleOnboardingScrolled(c, n)}
          ref={carouselRef}
        >
          {desktopView
            ? arrayOfOnboardingImagesInDesktop.map((image, index) => (
                <img src={image} alt={image} key={`desktop-${index}`} />
              ))
            : arrayOfOnboardingImagesInMobile.map((image, index) => (
                <img src={image} alt={image} key={`mobile-${index}`} />
              ))}
        </Carousel>,
      ]}
      footer={[
        <div key="footer" className={styles.modalFooter}>
          <Button className={customStyle.buttonCancel} onClick={handlePrev}>
            Back
          </Button>
          <Button type="primary" onClick={handleNext}>
            Next
          </Button>
        </div>,
      ]}
    />
  );
};
